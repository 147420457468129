import { AlertDialogAction, AlertDialogCancel, AlertDialogFooter } from 'components/ui/alert-dialog'
import { Form, FormControl, FormField, FormItem } from 'components/ui/form'
import { Textarea } from 'components/ui/textarea'
import { useTextForm } from 'modules/chat/hooks/use-text-form'
import { MessageType } from 'modules/chat/types/message.type'
import { TextFormSchemaType } from 'modules/chat/types/text-form.schema'
import { memo, useEffect, useRef } from 'react'

interface MessageEditFormProps {
    messageContent: MessageType['content']
    onSubmit: (values: TextFormSchemaType) => void | Promise<void>
}
export const MessageEditForm: React.FC<MessageEditFormProps> = memo(({ messageContent, onSubmit }) => {
    const form = useTextForm(messageContent)
    const watchText = form.watch('text')

    const ref = useRef<React.ComponentRef<typeof Textarea> | null>(null)

    useEffect(() => {
        if (!ref.current) {
            return
        }
        ref.current.style.height = 'auto'
        ref.current.style.height = `${Math.min(ref.current.scrollHeight, 400)}px`
    }, [form.watch, watchText])

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off" className="space-y-4">
                <FormField
                    name="text"
                    control={form.control}
                    render={({ field }) => (
                        <FormItem className="w-full">
                            <FormControl>
                                <Textarea
                                    {...field}
                                    ref={ref}
                                    placeholder="Write a message..."
                                    className="resize-none transition-all duration-300 selection:bg-slate-600 selection:text-gray-300"
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction type="submit">Save</AlertDialogAction>
                </AlertDialogFooter>
            </form>
        </Form>
    )
})
MessageEditForm.displayName = MessageEditForm.name
