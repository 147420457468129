import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { memo } from 'react'
import { getFallbackLetters } from 'utils/get-fallback-letters'

interface ChatAvatarProps extends React.ComponentProps<typeof Avatar> {
    fallback?: string
}
export const ChatAvatar: React.FC<ChatAvatarProps> = memo(({ fallback = 'Chat', ...props }) => {
    return (
        <Avatar {...props}>
            <AvatarImage />
            <AvatarFallback className="uppercase">{getFallbackLetters(fallback)}</AvatarFallback>
        </Avatar>
    )
})
ChatAvatar.displayName = ChatAvatar.name
