import { Button } from 'components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Switch } from 'components/ui/switch'
import { defaultIconProps } from 'config/constants'
import { Settings } from 'lucide-react'
import { useUserStore } from 'modules/user/store/user.store'
import { memo } from 'react'
import { useSignOut } from 'react-auth-kit'

interface ChatSettingsProps {}
export const ChatSettings: React.FC<ChatSettingsProps> = memo(() => {
    const notifications = useUserStore(state => state.notifications)
    const setNotifications = useUserStore(state => state.setNotifications)
    const signOut = useSignOut()

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="outline" size="icon">
                    <Settings {...defaultIconProps} />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
                <div className="space-y-5">
                    <div className="space-y-1">
                        <h4 className="text-lg font-semibold leading-none">Settings</h4>
                        <p className="text-muted-foreground text-sm">Configure everything here!</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between text-sm">
                            <p>Notifications</p>
                            <Switch checked={notifications} onCheckedChange={setNotifications} />
                        </div>
                    </div>
                    <Button
                        onClick={signOut}
                        className="bg-destructive/10 hover:bg-destructive/30 hover:text-accent-foreground text-destructive w-full"
                    >
                        Sign out
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    )
})
ChatSettings.displayName = ChatSettings.name
