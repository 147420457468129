import { defaultIconProps } from 'config/constants'
import { X } from 'lucide-react'
import { PropsWithChildren, memo } from 'react'
import { cn } from 'utils/cn'

const iconSizeClassName = 'h-[18px] min-h-[18px] w-[18px] min-w-[18px]'

interface SlotItemWithIconProps {
    Icon: React.ComponentType<React.ComponentProps<typeof X>>
    className?: string
}
export const SlotItemWithIcon: React.FC<PropsWithChildren<SlotItemWithIconProps>> = memo(
    ({ Icon, className, children, ...props }) => {
        return (
            <>
                <Icon {...defaultIconProps} {...props} className={cn('mr-3', iconSizeClassName, className)} />
                {children}
            </>
        )
    },
)
SlotItemWithIcon.displayName = SlotItemWithIcon.name

interface SlotItemWithIconReversedProps {
    Icon: React.ComponentType<React.ComponentProps<typeof X>>
    wrapperClassName?: string
    className?: string
}
export const SlotItemWithIconReversed: React.FC<PropsWithChildren<SlotItemWithIconReversedProps>> = memo(
    ({ Icon, wrapperClassName, className, children, ...props }) => {
        return (
            <div className={cn('flex w-full min-w-[160px]', wrapperClassName)}>
                {children}
                <Icon {...defaultIconProps} {...props} className={cn('ml-auto', iconSizeClassName, className)} />
            </div>
        )
    },
)
SlotItemWithIconReversed.displayName = SlotItemWithIconReversed.name
