import { Button } from 'components/ui/button'
import { ChevronLeft } from 'lucide-react'
import { memo } from 'react'
import { cn } from 'utils/cn'

interface BackButtonProps extends React.ComponentProps<typeof Button> {}
export const BackButton: React.FC<BackButtonProps> = memo(({ asChild = false, className, ...props }) => {
    return (
        <Button {...props} className={cn('hover:text-message text-message pl-1.5', className)}>
            <ChevronLeft />
            Back
        </Button>
    )
})
BackButton.displayName = BackButton.name
