import { App } from 'app'
import { AuthProvider } from 'modules/user/components/hoc/auth-provider'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import 'styles.css'

const element = document.getElementById('root') as HTMLElement
const root = createRoot(element)

root.render(
    <HelmetProvider>
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthProvider>
    </HelmetProvider>,
)
