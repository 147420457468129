import { LoadingButton } from 'components/loading-button'
import { Code } from 'components/ui/code'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'components/ui/dialog'
import { toastConfig } from 'config/toast.config'
import { ChatType } from 'modules/chat/types/chat.type'
import { memo, useCallback, useMemo, useState } from 'react'

interface ChatGptHelpModalProps extends React.ComponentProps<typeof DialogTrigger> {
    chat: ChatType | undefined
}
export const ChatGptHelpModal: React.FC<ChatGptHelpModalProps> = memo(({ chat, ...props }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [response, setResponse] = useState<string | undefined>()

    const lastMessage = useMemo(() => chat?.messages?.at(-1), [chat?.messages])

    const onConfirm = useCallback(async () => {
        try {
            setLoading(true)

            // const res = await fetch(`${urlConfig.API_URL}${urlConfig.api.gpt}`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         promt: lastMessage?.content,
            //     }),
            // })
            // const resJson = await res.json()

            const randInt = Math.floor(Math.random() * 100) + 1
            const res = await fetch(`https://jsonplaceholder.typicode.com/comments/${randInt}`)
            const resJson = (await res.json()) as {
                postId: number
                id: number
                name: string
                email: string
                body: string
            }
            setResponse(resJson.body)
        } catch (error) {
            console.error(error)
            toastConfig.unknownError()
        } finally {
            setLoading(false)
        }
    }, [])

    return (
        <Dialog>
            <DialogTrigger {...props} />
            <DialogContent className="selection:bg-slate-600 selection:text-gray-300">
                <DialogHeader>
                    <DialogTitle>Get help from ChatGPT</DialogTitle>
                    <DialogDescription>by pressing the button below</DialogDescription>
                </DialogHeader>

                <p>Message to help with:</p>
                <Code className="whitespace-pre-wrap py-2">{lastMessage?.content || 'How can I help?'}</Code>

                {response && (
                    <>
                        <p>Response:</p>
                        <Code className="whitespace-pre-wrap py-2">{response}</Code>
                    </>
                )}

                <LoadingButton onClick={onConfirm} loading={loading} className="w-fit">
                    Confirm
                </LoadingButton>
            </DialogContent>
        </Dialog>
    )
})
ChatGptHelpModal.displayName = ChatGptHelpModal.name
