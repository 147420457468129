import { z } from 'zod'

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5 MB

export const FileFormSchema = z.object({
    file: z
        .instanceof(File)
        .refine(file => file.size > 1, 'File is too small')
        .refine(file => file.size < MAX_FILE_SIZE, 'File is bigger than 5 MB')
        .refine(file => file.type.includes('image/'), 'File is not an image'),
})
export type FileFormSchemaType = z.infer<typeof FileFormSchema>
