import { TailwindIndicator } from 'components/tailwind-indicator'
import { Toaster } from 'components/ui/toaster'
import { urlConfig } from 'config/url.config'
import { ChatLayout } from 'modules/chat/layouts/chat.layout'
import { ThemeUpdater } from 'modules/theme/components/theme-updater'
import { RequireAuth } from 'modules/user/components/hoc/require-auth'
import { ThrowAuth } from 'modules/user/components/hoc/throw-auth'
import { ChatPage } from 'pages/chat-page'
import { ClientPage } from 'pages/client-page'
import { ErrorPage } from 'pages/error-page'
import { IndexPage } from 'pages/index-page'
import { LoginPage } from 'pages/login-page'
import { MainPage } from 'pages/main-page'
import { NotFoundPage } from 'pages/not-found-page'
import { memo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Route, Routes, useLocation } from 'react-router-dom'
import { NODE_ENV_DEV, NODE_ENV_PROD } from 'utils/NODE_ENV'

export const App: React.FC = memo(() => {
    const location = useLocation()

    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Routes>
                {/* auth only */}
                <Route
                    path={urlConfig.pages.main}
                    element={
                        <RequireAuth loginPath={urlConfig.pages.login}>
                            <ChatLayout />
                        </RequireAuth>
                    }
                >
                    <Route index element={<MainPage />} />
                    <Route path={urlConfig.pages.chatId} element={<ChatPage />} />
                </Route>
                {/* no auth */}
                <Route path={urlConfig.pages.client} element={<ClientPage />} />
                <Route
                    path={urlConfig.pages.login}
                    element={
                        <ThrowAuth callbackPath={urlConfig.pages.main}>
                            <LoginPage />
                        </ThrowAuth>
                    }
                />
                <Route index element={<IndexPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            {/*  */}
            <Toaster />
            <ThemeUpdater />
            <TailwindIndicator show={NODE_ENV_DEV && !NODE_ENV_PROD && location.pathname !== '/client'} />
        </ErrorBoundary>
    )
})
App.displayName = 'App'
