import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import { ContextMenuItem } from 'components/ui/context-menu'
import { DropdownMenuItem } from 'components/ui/dropdown-menu'
import { slotItemParentClassName } from 'config/constants'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { Trash2 } from 'lucide-react'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'

interface ChatDeleteSlotItemProps extends React.ComponentProps<typeof DropdownMenuItem> {
    chatId: ChatType['id'] | undefined
    Comp: typeof DropdownMenuItem | typeof ContextMenuItem
}
export const ChatDeleteSlotItem: React.FC<ChatDeleteSlotItemProps> = memo(({ chatId, Comp, className, children, ...props }) => {
    const navigate = useNavigate()
    const deleteChat = useChatStore(state => state.deleteChat)

    const onDelete = useCallback(async () => {
        try {
            if (!chatId) {
                throw new Error('No chat id')
            }
            await deleteChat(chatId)
            toastConfig.chat.delete.success()
            navigate(urlConfig.pages.main)
        } catch (error) {
            console.error(error)
            toastConfig.unknownError()
        }
    }, [chatId, deleteChat, navigate])

    return (
        <Comp
            onSelect={onDelete}
            {...props}
            className={cn(slotItemParentClassName, 'text-destructive focus:text-destructive', className)}
        >
            <SlotItemWithIconReversed Icon={Trash2}>Delete</SlotItemWithIconReversed>
        </Comp>
    )
})
ChatDeleteSlotItem.displayName = ChatDeleteSlotItem.name
