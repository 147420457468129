import { z } from 'zod'

export const BanReasonFormSchema = z.object({
    reason: z
        .string()
        .nonempty('Reason is required')
        .min(3, 'Reason must be at least 3 characters long')
        .max(255, 'Reason must be at most 255 characters long'),
})
export type BanReasonFormSchemaType = z.infer<typeof BanReasonFormSchema>
