import { firebaseCollections, firebaseFirestore } from 'config/firebase.config'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType, ChatTypeFieldsEnum } from 'modules/chat/types/chat.type'
import { Sidebar } from 'modules/sidebar/components/sidebar'
import { memo, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

interface ChatLayoutProps {}
export const ChatLayout: React.FC<ChatLayoutProps> = memo(() => {
    const fetchChats = useChatStore(state => state.fetchChats)
    const setAllChats = useChatStore(state => state.setAllChats)

    // initial chats fetch
    useEffect(() => {
        fetchChats()
    }, [fetchChats])

    // subscribe to chats changes
    useEffect(() => {
        const q = query(
            collection(firebaseFirestore, firebaseCollections.chats),
            where(ChatTypeFieldsEnum.IS_ARCHIVED, '==', false),
            where(ChatTypeFieldsEnum.IS_BANNED, '==', false),
        )
        const unsub = onSnapshot(q, snapshot => {
            const chats = snapshot.docs.map(
                doc =>
                    ({
                        ...doc.data(),
                        id: doc.id,
                    } as ChatType),
            )
            setAllChats(chats)
        })
        return () => {
            unsub()
        }
    }, [setAllChats])

    return (
        <div className="flex h-full">
            <Sidebar className="w-[400px]" />
            <Outlet />
        </div>
    )
})
ChatLayout.displayName = ChatLayout.name
