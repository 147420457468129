import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { useEffect } from 'react'

export const useRemoveNotificationBadge = (chat?: ChatType) => {
    const updateChat = useChatStore(state => state.updateChat)

    useEffect(() => {
        if (chat?.isUnread && chat) {
            updateChat({
                ...chat,
                isUnread: false,
            })
        }
    }, [chat, updateChat])
}
