import { zodResolver } from '@hookform/resolvers/zod'
import { BanReasonFormSchema, BanReasonFormSchemaType } from 'modules/user/types/schemas/ban-reason-form.schema'
import { useForm } from 'react-hook-form'

export const useBanReasonForm = (defaultValue = '') => {
    return useForm<BanReasonFormSchemaType>({
        resolver: zodResolver(BanReasonFormSchema),
        defaultValues: {
            reason: defaultValue,
        },
    })
}
