import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

interface UseUserStore {
    sidebarOpen: boolean
    notifications: boolean
    //
    toggleSidebar: () => void
    setNotifications: (value: boolean | ((prev: boolean) => boolean)) => void
}

export const useUserStore = create<
    UseUserStore,
    [['zustand/devtools', UseUserStore], ['zustand/persist', UseUserStore], ['zustand/immer', UseUserStore]]
>(
    devtools(
        persist(
            immer(set => ({
                sidebarOpen: true as boolean,
                notifications: true as boolean,

                //

                toggleSidebar: () => {
                    set(state => {
                        state.sidebarOpen = !state.sidebarOpen
                    })
                },

                //

                setNotifications: value => {
                    set(state => {
                        state.notifications = typeof value === 'function' ? value(state.notifications) : value
                    })
                },
            })),
            {
                name: 'user',
            },
        ),
    ),
)
