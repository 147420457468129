import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card'
import { Separator } from 'components/ui/separator'
import { getLocaleDate } from 'config/dayjs.config'
import { toastConfig } from 'config/toast.config'
import { ChatGptHelpModal } from 'modules/chat/components/chat-gpt-help-modal'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { Map } from 'modules/map/components/map'
import { MapMarker } from 'modules/map/components/map-marker'
import { GetBanReasonModal } from 'modules/user/components/get-ban-reason-modal'
import { useUserStore } from 'modules/user/store/user.store'
import { BanReasonFormSchemaType } from 'modules/user/types/schemas/ban-reason-form.schema'
import { memo, useCallback } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { cn } from 'utils/cn'

interface ChatSidebarProps extends React.ComponentProps<'div'> {
    chat: ChatType | undefined
}
export const ChatSidebar: React.FC<ChatSidebarProps> = memo(({ chat, className, ...props }) => {
    const isOpen = useUserStore(state => state.sidebarOpen)
    const ban = useChatStore(state => state.ban)
    const unban = useChatStore(state => state.unban)
    const auth = useAuthUser()

    const onBan = useCallback(
        async (values: BanReasonFormSchemaType) => {
            try {
                if (!chat) {
                    throw new Error('Chat is not defined')
                }
                await ban({
                    chat,
                    reason: values.reason,
                    bannedBy: auth()?.email ?? 'Unknown Admin',
                })
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            }
        },
        [auth, ban, chat],
    )

    const onUnban = useCallback(async () => {
        try {
            if (!chat) {
                throw new Error('Chat is not defined')
            }
            await unban(chat)
        } catch (error) {
            console.error(error)
            toastConfig.unknownError()
        }
    }, [chat, unban])

    return (
        <div
            {...props}
            className={cn(
                'flex w-[300px] flex-[1] flex-col justify-between border-l pb-11 pt-16 transition-all duration-300 selection:bg-slate-600 selection:text-gray-300',
                !isOpen && 'w-0 flex-[0] opacity-0',
                className,
            )}
        >
            <CardHeader className="space-y-3 pb-3">
                <CardTitle className="flex items-center justify-between">
                    <span>Chat Info</span>
                    {chat?.isBanned && <Badge>Banned</Badge>}
                </CardTitle>
                <CardDescription className="leading-relaxed">
                    {/* started */}
                    <span>Started {getLocaleDate(chat?.createdAt)}</span>
                    {/* banned */}
                    {chat?.isBanned && (
                        <span>
                            Banned by <b>{chat?.banInfo?.bannedBy}</b> at{' '}
                            {chat?.banInfo?.bannedAt ? getLocaleDate(chat?.banInfo?.bannedAt) : '...'} because of "
                            <b>{chat?.banInfo?.reason ?? '...'}</b>"
                        </span>
                    )}
                </CardDescription>
            </CardHeader>
            <CardContent className="pb-3">
                {/* GhatGPT help */}
                <ChatGptHelpModal chat={chat} asChild>
                    <Button className="w-full">Get help from ChatGPT</Button>
                </ChatGptHelpModal>
            </CardContent>
            <Separator className="mx-auto mb-2 w-[82%]" />
            <CardFooter className="mt-auto h-full">
                <div className="flex h-full w-full flex-col space-y-2">
                    {/* location */}
                    <div className="flex items-center justify-between">
                        <span className="text-muted-foreground text-xs">
                            {chat?.location?.city}, {chat?.location?.country}
                        </span>
                        <span className="text-muted-foreground text-xs">IP: {chat?.ip}</span>
                    </div>
                    <Map lat={chat?.location?.lat} lng={chat?.location?.lng} className="h-full overflow-hidden rounded-md border">
                        <MapMarker lat={chat?.location?.lat ?? 0} lng={chat?.location?.lng ?? 0} />
                    </Map>
                    {/* ban / unban */}
                    {chat?.isBanned ? (
                        <Button variant="outline" onClick={onUnban} className="w-full">
                            Unban
                        </Button>
                    ) : (
                        <GetBanReasonModal onSubmit={onBan} asChild className="w-full">
                            <Button variant="outline" className="w-full">
                                Ban
                            </Button>
                        </GetBanReasonModal>
                    )}
                </div>
            </CardFooter>
        </div>
    )
})
ChatSidebar.displayName = ChatSidebar.name
