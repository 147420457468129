import { zodResolver } from '@hookform/resolvers/zod'
import { SignInWithGoogle } from 'components/sign-in-with-google'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { pageTransitionClassName } from 'config/constants'
import { LoginEmailForm } from 'modules/user/components/login-email-form'
import { useLogin } from 'modules/user/hooks/use-login'
import { LoginSchema, LoginSchemaType } from 'modules/user/types/schemas/login.schema'
import { memo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { cn } from 'utils/cn'

interface LoginPageProps {}
export const LoginPage: React.FC<LoginPageProps> = memo(() => {
    const { isButtonDisabled, signInWithGoogle, signInWithEmailLink } = useLogin()

    const form = useForm<LoginSchemaType>({
        resolver: zodResolver(LoginSchema),
        defaultValues: {
            email: '',
        },
    })

    const onSubmit = useCallback(
        async (data: LoginSchemaType) => {
            await signInWithEmailLink(data.email, () => {
                form.reset()
            })
        },
        [form, signInWithEmailLink],
    )

    return (
        <div
            className={cn(
                "flex h-full min-h-screen w-full flex-col items-center justify-center gap-5 bg-[url('/src/assets/google-logo-light.svg')] bg-bottom dark:bg-[url('/src/assets/google-logo-dark.svg')]",
                pageTransitionClassName,
            )}
        >
            <Card className="w-[400px]">
                <CardHeader>
                    <CardTitle>Sign in to see this page</CardTitle>
                    <CardDescription>You can do it with email or google</CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col gap-5">
                    <LoginEmailForm form={form} onSubmit={onSubmit} loading={isButtonDisabled} />
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <span className="w-full border-t" />
                        </div>
                        <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-card text-muted-foreground px-2">Or continue with</span>
                        </div>
                    </div>
                    <SignInWithGoogle onClick={signInWithGoogle} disabled={isButtonDisabled} className="w-full" />
                </CardContent>
            </Card>
        </div>
    )
})
LoginPage.displayName = LoginPage.name
