import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(localeData)
dayjs.extend(weekOfYear)

type GetCurrentISODate = () => string
export const getCurrentISODate: GetCurrentISODate = () => dayjs().toISOString()

type GetLocaleDate = (date?: string) => string
export const getLocaleDate: GetLocaleDate = date => dayjs(date).format('DD MMMM YYYY, HH:mm:ss')

type GetLocaleTime = (date?: string) => string
export const getLocaleTime: GetLocaleTime = date => dayjs(date).format('HH:mm')

type GetRelativeTime = (
    date?: string,
    params?: {
        formatFn?: GetLocaleDate
        now?: boolean
        minutes?: boolean
        hours?: boolean
        days?: boolean
        weeks?: boolean
    },
) => string
export const getRelativeTime: GetRelativeTime = (
    date,
    params = {
        now: true,
        minutes: true,
        hours: true,
        days: true,
    },
) => {
    const nowDate = dayjs()
    const targetDate = dayjs(date)
    const diffMinutes = nowDate.diff(targetDate, 'minutes')
    const diffHours = nowDate.diff(targetDate, 'hours')
    const isToday = targetDate.isSame(nowDate, 'day')
    const isYesterday = targetDate.isSame(nowDate.subtract(1, 'day'), 'day')

    if (isToday) {
        if (diffMinutes < 1 && params?.now) {
            return 'Just now'
        }
        if (diffMinutes < 60 && params?.minutes) {
            return `${diffMinutes} m. ago`
        }
        if (diffHours < 24 && params?.hours) {
            return `${diffHours} h. ago`
        }
        if (params?.days) {
            return 'Today'
        }
    }
    if (isYesterday && params?.days) {
        return 'Yesterday'
    }
    if (targetDate.week() === nowDate.week() && params?.days) {
        return targetDate.format('dddd')
    }
    return params?.formatFn ? params.formatFn(date) : dayjs(date).format('DD.MM.YYYY')
}
