import { SVGProps, memo } from 'react'
import { Marker } from 'react-map-gl'
import { cn } from 'utils/cn'

interface MapMarkerProps extends Partial<SVGProps<SVGSVGElement>> {
    lat: number
    lng: number
}
export const MapMarker: React.FC<MapMarkerProps> = memo(({ lat, lng, className, ...props }) => {
    return (
        <Marker latitude={lat} longitude={lng} offset={[0, -18]}>
            <svg
                {...props}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className={cn(
                    'animate-in fade-in-0 zoom-in-50 animate-out fade-out-0 zoom-out-50 h-9 w-9 fill-red-600 outline-none drop-shadow transition-all duration-300',
                    className,
                )}
            >
                <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
            </svg>
        </Marker>
    )
})
MapMarker.displayName = MapMarker.name
