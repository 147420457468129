import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import { ContextMenuItem } from 'components/ui/context-menu'
import { DropdownMenuItem } from 'components/ui/dropdown-menu'
import { slotItemParentClassName } from 'config/constants'
import { Archive } from 'lucide-react'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { memo, useCallback } from 'react'

interface ChatArchiveSlotItemProps extends React.ComponentProps<typeof DropdownMenuItem> {
    Comp: typeof DropdownMenuItem | typeof ContextMenuItem
    chat: ChatType | undefined
    archived: boolean | undefined
}
export const ChatArchiveSlotItem: React.FC<ChatArchiveSlotItemProps> = memo(({ Comp, chat, archived, children, ...props }) => {
    const archiveChat = useChatStore(state => state.archiveChat)

    const onArchive = useCallback(() => {
        if (!chat || archived === undefined) {
            console.error(ChatArchiveSlotItem.name, 'No chat id or archived status provided')
            return
        }
        archiveChat(chat, archived)
    }, [archiveChat, archived, chat])

    return (
        <Comp {...props} onSelect={onArchive} className={slotItemParentClassName}>
            <SlotItemWithIconReversed Icon={Archive}>{archived ? 'Unarchive' : 'Archive'}</SlotItemWithIconReversed>
        </Comp>
    )
})
ChatArchiveSlotItem.displayName = ChatArchiveSlotItem.name
