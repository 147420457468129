import { PageTitle } from 'components/page-title'
import { pageTransitionClassName } from 'config/constants'
import { ChatContent } from 'modules/chat/components/chat-content'
import { ChatFooter } from 'modules/chat/components/chat-footer'
import { ChatHeader } from 'modules/chat/components/chat-header'
import { useChat } from 'modules/chat/hooks/use-chat'
import { useHandleImagePaste } from 'modules/chat/hooks/use-handle-image-paste'
import { useChatStore } from 'modules/chat/store/chat.store'
import { MessageRole } from 'modules/chat/types/message.type'
import { GetUsernameModal } from 'modules/user/components/get-username-modal'
import { GetUsernameFormSchemaType } from 'modules/user/types/schemas/get-username-form.schema'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import { getUserLocation } from 'modules/user/utils/get-user-location'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { cn } from 'utils/cn'

interface ClientPageProps {}
export const ClientPage: React.FC<ClientPageProps> = memo(() => {
    const clientChatId = useChatStore(state => state.clientChatId)
    const updateChat = useChatStore(state => state.updateChat)
    const createChat = useChatStore(state => state.createChat)
    const setClientChatid = useChatStore(state => state.setClientChatId)
    const fetchChatById = useChatStore(state => state.fetchChatById)

    const inputRef = useRef<React.ComponentRef<typeof ChatFooter>>(null)
    const lastMessageRef = useRef<React.ComponentRef<typeof ChatContent>>(null)

    const { chat, textForm, fileForm, isIdLoading, onSendMessage, onSendFile } = useChat({
        id: clientChatId,
        role: MessageRole.CLIENT,
        lastMessageRef,
    })

    const isGetUsernameModalOpen = useMemo(() => (chat?.username !== undefined && chat?.username.length === 0) || !chat, [chat])

    const onUsernameSubmit = useCallback(
        async (values: GetUsernameFormSchemaType) => {
            const ip = await getUserIp()
            const userLocation = await getUserLocation(ip)

            const newChat = await createChat()
            setClientChatid(newChat.id)

            await updateChat({
                ...newChat,
                location: {
                    lat: userLocation.lat,
                    lng: userLocation.lng,
                    city: userLocation.city,
                    country: userLocation.country,
                },
                ip,
                username: values.username,
            })
            fetchChatById(newChat.id)
        },
        [createChat, fetchChatById, setClientChatid, updateChat],
    )

    // focus input on chat change
    useEffect(() => {
        inputRef.current?.focus()
    }, [chat])

    // handle image paste
    useHandleImagePaste({
        form: fileForm,
    })

    return (
        <>
            <PageTitle />
            <div className={cn('relative flex h-full w-full flex-col', pageTransitionClassName)}>
                <GetUsernameModal initialOpen={isGetUsernameModalOpen} onSubmit={onUsernameSubmit} />
                <ChatHeader chat={chat} isClientPage={true} isLoading={isIdLoading} className="pl-2" />
                <ChatContent ref={lastMessageRef} chat={chat} role={MessageRole.CLIENT} isLoading={isIdLoading} className="" />
                <ChatFooter
                    ref={inputRef}
                    textForm={textForm}
                    onTextSubmit={onSendMessage}
                    fileForm={fileForm}
                    onFileFormSubmit={onSendFile}
                    isLoading={isIdLoading}
                    className="pl-2"
                />
            </div>
        </>
    )
})
ClientPage.displayName = ClientPage.name
