import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import { Button } from 'components/ui/button'
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuTrigger,
} from 'components/ui/context-menu'
import { slotItemParentClassName } from 'config/constants'
import { getRelativeTime } from 'config/dayjs.config'
import { urlConfig } from 'config/url.config'
import { MessageSquareDashed } from 'lucide-react'
import { ChatArchiveSlotItem } from 'modules/chat/components/chat-archive-slot-item'
import { ChatAvatar } from 'modules/chat/components/chat-avatar'
import { ChatDeleteSlotItem } from 'modules/chat/components/chat-delete-slot-item'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { MessageVariant } from 'modules/chat/types/message.type'
import { SidebarBadge } from 'modules/sidebar/components/sidebar-badge'
import { SidebarButtonText } from 'modules/sidebar/components/sidebar-button-text'
import { useUserStore } from 'modules/user/store/user.store'
import { memo, useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { cn } from 'utils/cn'

interface SidebarButtonProps extends React.ComponentProps<typeof Button> {
    chat: ChatType
    archived?: boolean
}
export const SidebarButton: React.FC<SidebarButtonProps> = memo(({ chat, archived = false, className, ...props }) => {
    const { id } = useParams()
    const notifications = useUserStore(state => state.notifications)
    const toggleChatUnread = useChatStore(state => state.toggleChatUnread)

    const isSelected = useMemo(() => id === chat.id, [chat.id, id])
    const lastMessage = useMemo(() => chat?.messages?.at(-1), [chat.messages])

    const onMarkAsReadOpposite = useCallback(() => {
        toggleChatUnread(chat)
    }, [chat, toggleChatUnread])

    return (
        <ContextMenu>
            <ContextMenuTrigger>
                <Button
                    key={chat.id}
                    variant="ghost"
                    asChild
                    {...props}
                    className={cn('group relative h-auto w-full justify-start', isSelected && 'bg-accent', className)}
                >
                    <Link to={urlConfig.pages.chatId.replace(':id', chat.id)} className="flex">
                        <SidebarBadge show={chat.isUnread && notifications} />
                        <ChatAvatar
                            fallback={chat.username}
                            className={cn('transition-all duration-300 group-hover:shadow', isSelected && 'shadow')}
                        />
                        <SidebarButtonText
                            title={chat.username}
                            content={
                                lastMessage?.type === MessageVariant.IMAGE ? 'Image' : lastMessage?.content ?? 'No messages yet'
                            }
                            time={lastMessage?.createdAt ? getRelativeTime(lastMessage?.createdAt) : ''}
                            className="ml-2 w-full"
                        />
                    </Link>
                </Button>
            </ContextMenuTrigger>
            <ContextMenuContent>
                <ContextMenuItem onSelect={onMarkAsReadOpposite} className={slotItemParentClassName}>
                    <SlotItemWithIconReversed Icon={MessageSquareDashed}>
                        Mark as {chat.isUnread ? 'read' : 'unread'}
                    </SlotItemWithIconReversed>
                </ContextMenuItem>
                <ChatArchiveSlotItem Comp={ContextMenuItem} chat={chat} archived={archived} />
                <ContextMenuSeparator />
                <ChatDeleteSlotItem Comp={ContextMenuItem} chatId={chat.id} />
            </ContextMenuContent>
        </ContextMenu>
    )
})
SidebarButton.displayName = SidebarButton.name
