import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

interface UseChatModalStore {
    sendImageModalOpen: boolean
    //
    setSendImageModalOpen: (value: boolean) => void
}

export const useChatModalStore = create<
    UseChatModalStore,
    [['zustand/devtools', UseChatModalStore], ['zustand/persist', UseChatModalStore], ['zustand/immer', UseChatModalStore]]
>(
    devtools(
        persist(
            immer((set, get) => ({
                sendImageModalOpen: false as boolean,

                //

                setSendImageModalOpen: value =>
                    set({
                        sendImageModalOpen: value,
                    }),
            })),
            {
                name: 'chat-modal',
            },
        ),
    ),
)
