import { ENV, dotenv } from 'config/dotenv.config'

export const urlConfig = {
    API_URL: dotenv.get(ENV.API_URL),
    pages: {
        main: '/',
        chatId: '/chat/:id',
        client: '/client',
        login: '/login',
        notFound: '/404',
    },
    api: {
        login: '/auth/login',
        user: '/user',
        gpt: '/gpt',
        userCoordinates: '/user/coordinates',
    },
}
