import { urlConfig } from 'config/url.config'
import { PropsWithChildren, memo } from 'react'
import { useIsAuthenticated } from 'react-auth-kit'
import { Navigate } from 'react-router-dom'

interface RequireAuthProps {
    loginPath?: string
}
export const RequireAuth: React.FC<PropsWithChildren<RequireAuthProps>> = memo(
    ({ loginPath = urlConfig.pages.login, children }) => {
        const isAuth = useIsAuthenticated()()

        return isAuth ? children : <Navigate to={loginPath} replace />
    },
)
RequireAuth.displayName = RequireAuth.name
