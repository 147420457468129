export interface BannedUserType {
    id: string
    //
    ip: string
    reason: string
    bannedBy: string
    bannedAt: string
}

export enum BannedUserFieldsEnum {
    IP = 'ip',
}
