import { SlotItemWithIcon } from 'components/slot-item-with-icon'
import { Button } from 'components/ui/button'
import {
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from 'components/ui/command'
import { urlConfig } from 'config/url.config'
import { BellDot, BellOff, ChevronLeft, ChevronRight, LogIn, LogOut, MessageSquare } from 'lucide-react'
import { useChatStore } from 'modules/chat/store/chat.store'
import { MessageVariant } from 'modules/chat/types/message.type'
import { ThemesIcons } from 'modules/theme/constants/theme.constant'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { useUserStore } from 'modules/user/store/user.store'
import { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { useIsAuthenticated, useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'
import { paramsToString } from 'utils/params-to-string'
import { substringSearch } from 'utils/substring-search'

interface CommandMenuProps extends React.ComponentProps<typeof Button> {}
export const CommandMenu: React.FC<CommandMenuProps> = memo(({ className, ...props }) => {
    // chats
    const chats = useChatStore(state => state.chats)
    const archivedChats = useChatStore(state => state.archivedChats)
    const bannedChats = useChatStore(state => state.bannedChats)
    // settings
    const notifications = useUserStore(state => state.notifications)
    const setNotifications = useUserStore(state => state.setNotifications)
    // themes
    const theme = useThemeStore(state => state.theme)
    const setTheme = useThemeStore(state => state.setTheme)
    // user
    const auth = useIsAuthenticated()
    const signOut = useSignOut()
    // router
    const navigate = useNavigate()

    const [open, setOpen] = useState<boolean>(false)

    const runCommand = useCallback((command: () => unknown) => {
        setOpen(false)
        command()
    }, [])

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault()
                setOpen(prev => !prev)
            }
        }
        document.addEventListener('keydown', down)
        return () => {
            document.removeEventListener('keydown', down)
        }
    }, [])

    return (
        <>
            <Button
                variant="outline"
                onClick={() => setOpen(true)}
                {...props}
                className={cn('text-muted-foreground relative w-full justify-start text-sm sm:pr-12', className)}
            >
                <span>Search anything...</span>
                <kbd className="bg-muted pointer-events-none absolute right-3 my-auto hidden h-5 select-none items-center gap-1 rounded border px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
                    <span className="text-xs">⌘</span>K
                </kbd>
            </Button>
            <CommandDialog
                filter={(value, search) => (substringSearch(value, search) ? 1 : 0)}
                open={open}
                onOpenChange={setOpen}
            >
                <CommandInput placeholder="Type a command to search..." />
                <CommandList className="py-1">
                    <CommandEmpty>No results found.</CommandEmpty>

                    {/* quick actions */}
                    <CommandGroup heading="Quick actions">
                        <CommandItem
                            value={paramsToString('go', 'back', 'quick action')}
                            onSelect={() => {
                                runCommand(() => navigate(-1))
                            }}
                        >
                            <SlotItemWithIcon Icon={ChevronLeft}>Back</SlotItemWithIcon>
                        </CommandItem>
                        <CommandItem
                            value={paramsToString('go', 'forward', 'quick action')}
                            onSelect={() => {
                                runCommand(() => navigate(1))
                            }}
                        >
                            <SlotItemWithIcon Icon={ChevronRight}>Forward</SlotItemWithIcon>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator />

                    {/* chats */}
                    {Object.entries({
                        Chats: chats,
                        'Archived chats': archivedChats,
                        'Banned chats': bannedChats,
                    }).map(([key, value]) => {
                        if (value.length === 0) {
                            return null
                        }
                        return (
                            <Fragment key={key}>
                                <CommandGroup heading={key}>
                                    {value.map(chat => (
                                        <CommandItem
                                            key={chat.id}
                                            value={paramsToString(
                                                'chat',
                                                chat.id,
                                                chat.messages?.at(-1)?.content ?? 'No messages yet',
                                            )}
                                            onSelect={() => {
                                                runCommand(() => navigate(urlConfig.pages.chatId.replace(':id', chat.id)))
                                            }}
                                            className="group"
                                        >
                                            <SlotItemWithIcon Icon={MessageSquare} className="">
                                                <span className="w-full max-w-[35%] overflow-hidden overflow-ellipsis whitespace-nowrap">
                                                    {chat.username}
                                                </span>
                                                <span className="text-border group-hover:text-foreground/50 px-2 transition-colors duration-300">
                                                    |
                                                </span>
                                                <span className="text-muted-foreground w-full max-w-[35%] overflow-hidden overflow-ellipsis whitespace-nowrap">
                                                    {chat.messages?.at(-1)?.type === MessageVariant.IMAGE
                                                        ? 'Image'
                                                        : chat.messages?.at(-1)?.content ?? 'No messages yet'}
                                                </span>
                                            </SlotItemWithIcon>
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                                <CommandSeparator />
                            </Fragment>
                        )
                    })}

                    {/* settings */}
                    <CommandGroup heading="Settings">
                        <CommandItem
                            value={paramsToString('settings', 'notifications', 'notify', 'badge')}
                            onSelect={() => {
                                runCommand(() => setNotifications(prev => !prev))
                            }}
                            className="group"
                        >
                            <SlotItemWithIcon Icon={notifications ? BellDot : BellOff}>
                                Notifications
                                <span className="text-border group-hover:text-foreground/50 px-2 transition-colors duration-300">
                                    |
                                </span>
                                <span className="text-muted-foreground">{notifications ? 'On' : 'Off'}</span>
                            </SlotItemWithIcon>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator />

                    {/* themes */}
                    <CommandGroup heading="Themes">
                        {Object.entries(ThemesIcons).map(([key, Value]) => (
                            <CommandItem
                                key={key}
                                value={paramsToString('theme', 'change', key)}
                                onSelect={() => {
                                    runCommand(() => setTheme(key as keyof typeof ThemesIcons))
                                }}
                                className="group"
                            >
                                <SlotItemWithIcon Icon={Value}>
                                    <span className="capitalize">{key}</span>
                                    {theme.toLowerCase() === key.toLowerCase() && (
                                        <>
                                            <span className="text-border group-hover:text-foreground/50 px-2 transition-colors duration-300">
                                                |
                                            </span>
                                            <span className="text-muted-foreground">Active</span>
                                        </>
                                    )}
                                </SlotItemWithIcon>
                            </CommandItem>
                        ))}
                    </CommandGroup>
                    <CommandSeparator />

                    {/* users */}
                    <CommandGroup heading="User">
                        {auth() ? (
                            <CommandItem
                                value={paramsToString('user', 'logout', 'signout')}
                                onSelect={() => {
                                    runCommand(() => signOut())
                                }}
                                className="group"
                            >
                                <SlotItemWithIcon Icon={LogOut}>Sign out</SlotItemWithIcon>
                            </CommandItem>
                        ) : (
                            <CommandItem
                                value={paramsToString('user', 'login', 'sign in')}
                                onSelect={() => {
                                    runCommand(() => navigate(urlConfig.pages.login))
                                }}
                            >
                                <SlotItemWithIcon Icon={LogIn}>Sign in</SlotItemWithIcon>
                            </CommandItem>
                        )}
                    </CommandGroup>
                </CommandList>
            </CommandDialog>
        </>
    )
})
CommandMenu.displayName = CommandMenu.name
