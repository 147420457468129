export enum MessageRole {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
}

export enum MessageVariant {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
}

export interface MessageType {
    createdAt: string
    //
    type: MessageVariant
    senderRole: MessageRole
    content: string
}
