import { Button } from 'components/ui/button'
import { pageTransitionClassName } from 'config/constants'
import { urlConfig } from 'config/url.config.ts'
import { memo } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'

interface ErrorPageProps {
    error: Error
}
export const ErrorPage: React.FC<ErrorPageProps> = memo(({ error }) => {
    const { resetBoundary } = useErrorBoundary()

    console.log('=====   Error:   =====')
    console.error(error)
    console.log('=====   End of error   =====')

    return (
        <div
            className={cn('flex h-full min-h-screen w-full flex-col items-center justify-center gap-8', pageTransitionClassName)}
        >
            <h1 className="text-5xl">500 | Error occured</h1>
            <Button asChild onClick={resetBoundary}>
                <Link to={urlConfig.pages.main}>Go Home</Link>
            </Button>
        </div>
    )
})
ErrorPage.displayName = ErrorPage.name
