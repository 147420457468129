import { LoadingButton } from 'components/loading-button'
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { toastConfig } from 'config/toast.config'
import { useBanReasonForm } from 'modules/user/hooks/use-ban-reason-form'
import { BanReasonFormSchemaType } from 'modules/user/types/schemas/ban-reason-form.schema'
import { memo, useCallback, useEffect, useRef, useState } from 'react'

interface GetBanReasonModalProps extends Omit<React.ComponentProps<typeof AlertDialogTrigger>, 'onSubmit'> {
    onSubmit: (values: BanReasonFormSchemaType) => void | Promise<void>
}
export const GetBanReasonModal: React.FC<GetBanReasonModalProps> = memo(({ onSubmit, ...props }) => {
    const form = useBanReasonForm()

    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const inputRef = useRef<React.ComponentRef<typeof Input> | null>(null)

    const handleSubmit = useCallback(
        async (values: BanReasonFormSchemaType) => {
            try {
                setLoading(true)
                await onSubmit(values)
                setOpen(false)
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            } finally {
                setLoading(false)
            }
        },
        [onSubmit],
    )

    // focus input on open
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                inputRef.current?.focus()
            }, 50)
        }
    }, [open])

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger {...props} />
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>You can undo this action later.</AlertDialogDescription>
                </AlertDialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-5">
                        <FormField
                            name="reason"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Reason</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            ref={inputRef}
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Because of..."
                                        />
                                    </FormControl>
                                    <FormDescription>Why should this user be banned?</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <AlertDialogFooter>
                            <AlertDialogCancel disabled={loading}>Cancel</AlertDialogCancel>
                            <LoadingButton type="submit" loading={loading}>
                                Submit
                            </LoadingButton>
                        </AlertDialogFooter>
                    </form>
                </Form>
            </AlertDialogContent>
        </AlertDialog>
    )
})
GetBanReasonModal.displayName = GetBanReasonModal.name
