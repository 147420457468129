import { urlConfig } from 'config/url.config'

type GetUserLocation = (ip: string) => Promise<{
    lat: number
    lng: number
    city: string
    country: string
}>
export const getUserLocation: GetUserLocation = async ip => {
    const res = await fetch(`${urlConfig.API_URL}${urlConfig.api.userCoordinates}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ip,
        }),
    })
    return (await res.json()) as ReturnType<GetUserLocation>
}
