import { z } from 'zod'

export const GetUsernameFormSchema = z.object({
    username: z
        .string()
        .nonempty('Username is required')
        .min(2, 'Username must be at least 2 characters long')
        .max(50, 'Username must be at most 50 characters long'),
})
export type GetUsernameFormSchemaType = z.infer<typeof GetUsernameFormSchema>
