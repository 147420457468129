import { zodResolver } from '@hookform/resolvers/zod'
import { TextFormSchema, TextFormSchemaType } from 'modules/chat/types/text-form.schema'
import { useForm } from 'react-hook-form'

export const useTextForm = (defaultValue = '') => {
    return useForm<TextFormSchemaType>({
        resolver: zodResolver(TextFormSchema),
        defaultValues: {
            text: defaultValue,
        },
    })
}
