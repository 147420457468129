import { toastConfig } from 'config/toast.config'
import { useChatModalStore } from 'modules/chat/store/chat-modal.store'
import { FileFormSchemaType } from 'modules/chat/types/file-form.schema'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

type UseHandleImagePaste = (params: { form: UseFormReturn<FileFormSchemaType> }) => void
export const useHandleImagePaste: UseHandleImagePaste = ({ form }) => {
    const setOpen = useChatModalStore(state => state.setSendImageModalOpen)

    useEffect(() => {
        const paste = (e: ClipboardEvent) => {
            const file = e.clipboardData?.files?.[0]
            // no file
            if (!file) {
                return
            }
            // file is not image
            if (!file.type.startsWith('image/')) {
                toastConfig.chat.sendImage.notImage()
                return
            }
            e.preventDefault()
            form.setValue('file', file)
            setOpen(true)
        }
        document.addEventListener('paste', paste)
        return () => {
            document.removeEventListener('paste', paste)
        }
    }, [form, setOpen])
}
