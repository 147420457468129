export const defaultIconProps = {
    strokeWidth: 1.5,
} as const

export const pageTransitionClassName = 'animate-in fade-in-0 transition-all duration-300' as const

export const slotItemParentClassName = 'group cursor-pointer pr-4' as const
export const slotItemIconClassName =
    'mr-1.5 aspect-square h-[1.125rem] transition-all duration-300 group-hover:scale-105' as const

export const defaultTitle = 'Service Chat' as const
