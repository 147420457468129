import dayjs from 'dayjs'
import { ChatType } from 'modules/chat/types/chat.type'

type GetChatFileName = (chat: ChatType) => string
export const getChatFileName: GetChatFileName = chat => {
    const nowIso = dayjs().toISOString()
    const usernameNoSpaces = chat.username.replace(/\s/g, '')
    return [chat.id, usernameNoSpaces, nowIso].join('__')
}

type IsFileFromChat = (mediaName: string, chat: ChatType) => boolean
export const isFileFromChat: IsFileFromChat = (mediaName, chat) => {
    const chatMediaName = getChatFileName(chat)
    return mediaName.includes(chatMediaName)
}
