import { InlineCode } from 'components/ui/code'
import { toast } from 'components/ui/use-toast'

export const toastConfig = {
    login: {
        success: (name: string) =>
            toast({
                title: 'Login successful',
                description: (
                    <>
                        Welcome, <InlineCode>{name}</InlineCode>
                    </>
                ),
            }),
        error: () =>
            toast({
                title: 'Failed to login',
                description: 'Please try again later',
                variant: 'destructive',
            }),
        successWithEmail: (email: string) =>
            toast({
                title: 'First step is done!',
                description: (
                    <>
                        Check your email <InlineCode>{email}</InlineCode> to confirm it. If you don't see the letter, check the
                        spam folder
                    </>
                ),
            }),
    },

    chat: {
        delete: {
            success: () =>
                toast({
                    title: 'Chat deleted',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete chat',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        sendMessage: {
            notAllowed: () =>
                toast({
                    title: 'You are not allowed to send messages here',
                    description: 'Please contact the administrator',
                    variant: 'destructive',
                }),
        },
        sendImage: {
            notImage: () =>
                toast({
                    title: 'File is not an image',
                    description: 'Please select an image',
                    variant: 'destructive',
                }),
        },
    },

    unknownError: () =>
        toast({
            title: 'Unknown error',
            description: 'Please try again later',
        }),
}
