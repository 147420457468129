import { defaultTitle } from 'config/constants'
import { memo } from 'react'
import { Helmet } from 'react-helmet-async'

interface PageTitleProps {
    children?: string
}
export const PageTitle: React.FC<PageTitleProps> = memo(({ children }) => {
    return (
        <Helmet>
            <title>
                {children ? `${children} | ` : ''}
                {defaultTitle}
            </title>
        </Helmet>
    )
})
PageTitle.displayName = PageTitle.name
