import { CommandMenu } from 'components/command-menu'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Separator } from 'components/ui/separator'
import { Skeleton } from 'components/ui/skeleton'
import dayjs from 'dayjs'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType } from 'modules/chat/types/chat.type'
import { SidebarButton } from 'modules/sidebar/components/sidebar-button'
import { ThemeSelector } from 'modules/theme/components/theme-selector'
import { Fragment, memo, useCallback } from 'react'
import { cn } from 'utils/cn'

interface SidebarProps extends React.ComponentProps<'div'> {}
export const Sidebar: React.FC<SidebarProps> = memo(({ className, children, ...props }) => {
    const chats = useChatStore(state => state.chats)
    const archivedChats = useChatStore(state => state.archivedChats)
    const bannedChats = useChatStore(state => state.bannedChats)
    const isAllLoading = useChatStore(state => state.isAllLoading)

    const sortChatsByLastMessage = useCallback<(a: ChatType, b: ChatType) => number>((a, b) => {
        if (!a.messages?.length) return -1
        if (!b.messages?.length) return 1
        const dateA = dayjs(a.messages?.at(-1)?.createdAt)
        const dateB = dayjs(b.messages?.at(-1)?.createdAt)
        return dateB.diff(dateA)
    }, [])

    // skeleton loading
    if (isAllLoading) {
        return (
            <div {...props} className={cn('h-screen p-2', className)}>
                <Card className="no-scrollbar h-full w-full overflow-y-scroll">
                    <CardContent>
                        {new Array(20).fill(0).map((_, index) => (
                            <Fragment key={index}>
                                {index !== 0 && <Separator />}
                                <div className="mt-1 flex h-auto gap-2 px-4 py-2">
                                    <Skeleton className="h-10 w-12 rounded-full" />
                                    <div className="flex h-full w-full flex-col gap-2">
                                        <Skeleton className="h-5 w-full" />
                                        <Skeleton className="mt-2 h-3 w-full" />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <div {...props} className={cn('h-screen p-2', className)}>
            <Card className="no-scrollbar h-full w-full overflow-y-scroll selection:bg-slate-600 selection:text-gray-300">
                {/* command menu */}
                <CardHeader>
                    <CommandMenu />
                </CardHeader>

                <div className="mt-5" />

                {/* chats */}
                {Object.entries({
                    'Your chats': chats,
                    Archived: archivedChats,
                    Banned: bannedChats,
                }).map(([key, value]) => {
                    if (value.length === 0) {
                        return null
                    }
                    return (
                        <Fragment key={key}>
                            <CardHeader className="pt-0">
                                <CardTitle>{key}</CardTitle>
                            </CardHeader>
                            <CardContent>
                                {/* @ts-expect-error toSorted - new array method */}
                                {value?.toSorted(sortChatsByLastMessage)?.map((chat, index) => (
                                    <Fragment key={chat.id}>
                                        {index !== 0 && <Separator />}
                                        <SidebarButton
                                            archived={key.toLowerCase().includes('archived')}
                                            chat={chat}
                                            className="my-1"
                                        />
                                    </Fragment>
                                ))}
                            </CardContent>
                        </Fragment>
                    )
                })}
            </Card>
        </div>
    )
})
Sidebar.displayName = Sidebar.name
