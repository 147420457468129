import { PageTitle } from 'components/page-title'
import { CardDescription } from 'components/ui/card'
import { pageTransitionClassName } from 'config/constants'
import { ChatSettings } from 'modules/chat/components/chat-settings'
import { ThemeSelector } from 'modules/theme/components/theme-selector'
import { memo } from 'react'
import { cn } from 'utils/cn'

interface MainPageProps {}
export const MainPage: React.FC<MainPageProps> = memo(() => {
    return (
        <>
            <PageTitle />
            <div className={cn('flex h-screen w-full items-center justify-center', pageTransitionClassName)}>
                <div className="absolute right-0 top-0 flex gap-3 p-5">
                    <ThemeSelector />
                    <ChatSettings />
                </div>
                <CardDescription className="text-center">Select a chat to start messaging</CardDescription>
            </div>
        </>
    )
})
MainPage.displayName = MainPage.name
