import { zodResolver } from '@hookform/resolvers/zod'
import { GetUsernameFormSchema, GetUsernameFormSchemaType } from 'modules/user/types/schemas/get-username-form.schema'
import { useForm } from 'react-hook-form'

export const useGetUsernameForm = (defaultValue = '') => {
    return useForm<GetUsernameFormSchemaType>({
        resolver: zodResolver(GetUsernameFormSchema),
        defaultValues: {
            username: defaultValue,
        },
    })
}
