import { MessageType } from 'modules/chat/types/message.type'
import { BannedUserType } from 'modules/user/types/banned-user.type'

export interface ChatType {
    id: string
    createdAt: string
    //
    isUnread: boolean
    isArchived: boolean
    isBanned: boolean
    //
    banInfo: Pick<BannedUserType, 'reason' | 'bannedBy' | 'bannedAt'> | null
    location: {
        lat: number
        lng: number
        city: string
        country: string
    }
    ip: string
    username: string
    messages: MessageType[]
}

export enum ChatTypeFieldsEnum {
    IS_ARCHIVED = 'isArchived',
    IS_BANNED = 'isBanned',
}
